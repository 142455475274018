import FS from '../FetchService';

export default class StudentService {
  static getCourses() {
    return FS.get('/current_user/courses?limit=0');
  }

  static tourCompleted() {
    return FS.post('/current_user/complete_onboarding');
  }

  static pageMedia(params, { CourseInstanceID }) {
    return FS.get(`/current_user/courses/${CourseInstanceID}/media?${params}`);
  }

  static orderMedia(Media, SortPriority, { CourseInstanceID:cid }) {
    return FS.patch(`/current_user/courses/${cid}/media/sort_priority`, JSON.stringify({ Media, SortPriority }), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static orderTeacherMedia(Media, SortPriority, { CourseInstanceID:cid }) {
    return FS.patch(`/current_user/courses/${cid}/teacher_media/sort_priority`, JSON.stringify({ Media, SortPriority }), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static pageTeacherMedia(params, { CourseInstanceID }) {
    return FS.get(`/current_user/courses/${CourseInstanceID}/teacher_media?${params}`);
  }

  static pageLibrary(params) {
    return FS.get(`/current_user/library?${params}`);
  }

  static pageTeacherLibrary(params) {
    return FS.get(`/current_user/teacher_library?${params}`);
  }

  static modifyMedia = (method, key) => async (Media, { CourseInstanceID:cid }) => {
    return FS[method](`/current_user/courses/${cid}/${key}`, JSON.stringify(Media), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }
  static addMedia = StudentService.modifyMedia('post', 'media');
  static removeMedia = StudentService.modifyMedia('delete', 'media');
  static addTeacherMedia = StudentService.modifyMedia('post', 'teacher_media');
  static removeTeacherMedia = StudentService.modifyMedia('delete', 'teacher_media');
}