import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import { TooltipContext } from "./TooltipProvider";

import { v4 as uuidv4 } from "uuid";

import { throttle } from "lodash";
import { calculateFloatingPosition } from "utils/floating_position";

export const TooltipContainer = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ color }) => (color === "black" ? "white" : "initial")};
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 8px;
  transition: top 0.02s linear, right 0.02s linear;
`;
TooltipContainer.displayName = "TooltipContainer";
TooltipContainer.defaultProps = { color: "white" };

const Tooltip = (props) => {
  const { setShowTooltip, clearTooltip } = useContext(TooltipContext);

  const { children, tooltip, tooltipOptions: _tooltipOptions } = props;

  const tooltipOptions = useMemo(
    () => ({
      anchor: "mouse", // "mouse", "element"
      float: {
        vertical: "top", // "auto", "bottom", "center", "top"
        horizontal: "auto", // "auto", "left", "center", "right"
      },
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      noContainer: false,
      containerProps: {},
      timeoutTime: 25,
      interactiveTooltip: false,
      delay: 250,
      ..._tooltipOptions,
    }),
    [_tooltipOptions]
  );

  const newRef = useRef();
  const childProps = useMemo(() => {
    const childProps = children.props ? { ...children.props } : {};
    if (!children.ref) childProps.ref = newRef;
    return childProps;
  }, [children.props, children.ref]);

  const newChildren = useMemo(
    () => React.cloneElement(children, childProps),
    [children, childProps]
  );

  const childRef = useMemo(() => newChildren.ref, [newChildren.ref]);

  const [state, setState] = useState({
    active: false,
    position: {},
    autoFloat: {},
    timeoutRef: null,
    hoverDelayRef: null,
  });

  const getAnchorRect = useCallback(
    (e) => {
      if (tooltipOptions.anchor === "mouse") {
        return {
          top: e.clientY,
          left: e.clientX,
          right: e.clientX + 14,
          bottom: e.clientY + 18,
        };
      } else if (tooltipOptions.anchor === "element" && childRef.current) {
        return childRef.current.getBoundingClientRect();
      }

      return { top: 0, left: 0, right: 0, bottom: 0 };
    },
    [tooltipOptions.anchor, childRef]
  );

  const activateTooltip = useCallback(
    (tooltipPosition) => {
      const activate = () => {
        setState((orig) => ({
          ...orig,
          hoverDelayRef: null,
          position: tooltipPosition.position,
          autoFloat: tooltipPosition.autoFloats,
          active: true,
        }));
      };

      clearTimeout(state.hoverDelayRef);

      if (tooltipOptions.delay) {
        setState((orig) => ({
          ...orig,
          hoverDelayRef: setTimeout(activate, tooltipOptions.delay),
        }));
      } else {
        activate();
      }
    },
    [state.hoverDelayRef, tooltipOptions.delay]
  );

  const handleMouseEnter = useCallback(
    (e) => {
      const tooltipPosition = calculateFloatingPosition(
        getAnchorRect(e),
        tooltipOptions.float,
        tooltipOptions.padding
      );

      activateTooltip(tooltipPosition);
    },
    [
      activateTooltip,
      tooltipOptions.float,
      tooltipOptions.padding,
      getAnchorRect,
    ]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (tooltipOptions.anchor === "mouse") {
        const float = {
          vertical: state.autoFloat.vertical || tooltipOptions.float.vertical,
          horizontal:
            state.autoFloat.horizontal || tooltipOptions.float.horizontal,
        };

        const tooltipPosition = calculateFloatingPosition(
          getAnchorRect(e),
          float,
          tooltipOptions.padding
        );

        if (!state.active && tooltipOptions.delay) {
          activateTooltip(tooltipPosition);
        } else {
          setState((orig) => {
            if (orig.timeoutRef) clearTimeout(orig.timeoutRef);
            return {
              ...orig,
              position: tooltipPosition.position,
              timeoutRef: null,
            };
          });
        }
      } else {
        setState((orig) => {
          if (orig.timeoutRef) clearTimeout(orig.timeoutRef);
          return {
            ...orig,
            timeoutRef: null,
          };
        });
      }
    },
    [
      state.autoFloat,
      state.active,
      tooltipOptions.anchor,
      tooltipOptions.float,
      tooltipOptions.padding,
      tooltipOptions.delay,
      activateTooltip,
      getAnchorRect,
    ]
  );

  const throttledMouseMove = useMemo(
    () =>
      throttle(handleMouseMove, 1000 / 60, { leading: true, trailing: false }),
    [handleMouseMove]
  );

  const handleMouseLeave = useCallback(
    (e) => {
      setTimeout(() =>
        setState((orig) => ({
          ...orig,
          hoverDelayRef: clearTimeout(orig.hoverDelayRef),
        }))
      );

      const deactivate = () => {
        setState((orig) => ({
          ...orig,
          active: false,
          timeoutRef: null,
        }));
      };

      if (!!tooltipOptions.timeoutTime) {
        setState((orig) => {
          if (orig.timeoutRef) clearTimeout(orig.timeoutRef);
          return {
            ...orig,
            timeoutRef: setTimeout(deactivate, tooltipOptions.timeoutTime),
          };
        });
      } else {
        deactivate();
      }
    },
    [tooltipOptions.timeoutTime]
  );

  useEffect(
    () => () => state.timeoutRef && clearTimeout(state.timeoutRef),
    [state.timeoutRef]
  );

  useEffect(
    () => () => state.hoverDelayRef && clearTimeout(state.hoverDelayRef),
    [state.hoverDelayRef]
  );

  useEffect(() => {
    const cRef = childRef.current;
    cRef && cRef.addEventListener("mouseover", handleMouseEnter);
    return () => {
      cRef && cRef.removeEventListener("mouseover", handleMouseEnter);
    };
  }, [handleMouseEnter, childRef]);
  useEffect(() => {
    const cRef = childRef.current;
    cRef && cRef.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      cRef && cRef.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [handleMouseLeave, childRef]);
  useEffect(() => {
    const cRef = childRef.current;
    cRef && cRef.addEventListener("mousemove", throttledMouseMove);
    return () => {
      cRef && cRef.removeEventListener("mousemove", throttledMouseMove);
    };
  }, [throttledMouseMove, childRef]);

  const handleTooltipMouseMove = useCallback((e) => {
    setState((orig) => {
      if (orig.timeoutRef) clearTimeout(orig.timeoutRef);
      return {
        ...orig,
        timeoutRef: null,
      };
    });
  }, []);

  const tooltipComponent = useMemo(() => {
    const positionStyle = { position: "absolute", ...state.position };

    let component = tooltip;

    if (!tooltipOptions.noContainer) {
      component = (
        <TooltipContainer
          style={positionStyle}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleTooltipMouseMove}
          {...tooltipOptions.containerProps}
        >
          {component}
        </TooltipContainer>
      );
    } else if (React.isValidElement(tooltip)) {
      const tooltipProps = tooltip.props ? { ...tooltip.props } : {};

      if (tooltipOptions.interactiveTooltip) {
        tooltipProps.onMouseLeave = handleMouseLeave;
        tooltipProps.onMouseMove = handleTooltipMouseMove;
      }

      const tooltipStyle = tooltipProps.style
        ? { ...tooltipProps.style, ...positionStyle }
        : positionStyle;

      tooltipProps.style = tooltipStyle;

      component = React.cloneElement(tooltip, tooltipProps);
    }

    return component;
  }, [
    tooltip,
    state.position,
    tooltipOptions.interactiveTooltip,
    tooltipOptions.containerProps,
    tooltipOptions.noContainer,
    handleMouseLeave,
    handleTooltipMouseMove,
  ]);

  const tooltipId = useRef(uuidv4());

  useEffect(() => {
    const tID = tooltipId.current;

    setShowTooltip(tID, tooltipComponent, tooltip && state.active);

    return () => clearTooltip(tID);
  }, [tooltipComponent, tooltip, state.active, setShowTooltip, clearTooltip]);

  return newChildren;
};

export default Tooltip;
