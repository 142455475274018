/**
 * Entry point of the entire application. Don't be too worried about how many things are going on here.
 *
 * The "REAL" entry point, or the "Application" itself is in `App.js`
 *
 * This file simply scaffolds all the global state objects (redux),
 * redux middle-wares (such as thunk which lets us create asynchronous redux actions),
 * and whichever other react plugins we want to use (like react-router).
 *
 * We can also import global css files from plugins, and our own css, though we use
 * styled-components which is a per-component based styling plugin.
 */

import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-image-crop/dist/ReactCrop.css";
import "katex/dist/katex.css";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";

import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

import reducer from "./reducers";
import logger from "middleware/logger";

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Global application state. All react components will read from this through
// react context.
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);

// Render our application into <div id="root"></div> from index.html
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// Temporarily disable service workers.
unregister();

// For development only, we can inject new code right into the webb page without reloading.
if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  });

  module.hot.accept("./reducers", () => {
    store.replaceReducer(reducer);
  });
}
