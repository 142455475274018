import React from 'react';
import styled from 'styled-components';
import media from '../media/media';

export const ChalkboardText = styled.div`
	font-family: erasdust;
	font-size: 5vw;
	color: #e0e0e0;
	text-align: center;

	${media.landscape`
    font-size: 8vw;
  `}
	${media.mobile`
    font-size: 12vw;
  `}
`;
ChalkboardText.displayName = 'ChalkboardText';

export const ChalkboardWood = styled.div`
	width: 50%;

	box-shadow: 2px 2px 6px 0px #00000052;

	background-color: #825928;
	padding: 30px;

	${media.landscape`
    width: 60%;
  `}

	${media.mobile`
    width: 90%;
  `}
`;
ChalkboardWood.displayName = 'ChalkboardWood';

export const ChalkboardContent = styled.div`
	background-color: #28483a;
	box-shadow: 0 0 0 3px #153728;

	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
ChalkboardContent.displayName = 'ChalkboardContent';

export const Chalkboard = (props) => (
	<ChalkboardWood style={props.style}>
		<ChalkboardContent>{props.children}</ChalkboardContent>
	</ChalkboardWood>
);
