const calculateFloatingPositionValue = (
  keys,
  anchorRect,
  windowRect,
  float,
  padding
) => {
  let auto = float === "auto";
  if (auto) {
    if (anchorRect[keys.min] > windowRect[keys.max] / 2) {
      float = keys.min;
    } else {
      float = keys.max;
    }
  }

  const retVal = {};
  if (float === keys.min) {
    retVal[keys.max] = `${
      windowRect[keys.max] - anchorRect[keys.min] + padding
    }px`;
  } else if (float === keys.max) {
    retVal[keys.min] = `${anchorRect[keys.max] + padding}px`;
  } else if (float === "center") {
    retVal[keys.min] = `${(anchorRect[keys.min] + anchorRect[keys.max]) / 2}px`;
    retVal["transform"] = `translate${keys.center}(-50%)`;
  }

  if (auto) {
    retVal["autoFloat"] = float;
  } else {
    retVal["autoFloat"] = false;
  }

  if (!retVal["transform"]) retVal["transform"] = false;

  return retVal;
};

export const calculateFloatingPosition = (
  anchorRect = { top: 0, left: 0, right: 0, bottom: 0 },
  float = { vertical: "bottom", horizontal: "auto" },
  padding = { vertical: 0, horizontal: 0 }
) => {
  const windowRect = {
    top: 0,
    left: 0,
    right: document.documentElement.clientWidth,
    bottom: document.documentElement.clientHeight,
  };

  const {
    autoFloat: vAutoFloat,
    transform: vTransform,
    ...verticalPosition
  } = calculateFloatingPositionValue(
    {
      min: "top",
      max: "bottom",
      center: "Y",
    },
    anchorRect,
    windowRect,
    float.vertical,
    padding.vertical
  );

  const {
    autoFloat: hAutoFloat,
    transform: hTransform,
    ...horizontalPosition
  } = calculateFloatingPositionValue(
    {
      min: "left",
      max: "right",
      center: "X",
    },
    anchorRect,
    windowRect,
    float.horizontal,
    padding.horizontal
  );

  const transform = {};
  if (vTransform || hTransform) {
    transform.transform = `${vTransform || ""} ${hTransform || ""}`;
  }

  return {
    position: {
      ...verticalPosition,
      ...horizontalPosition,
      ...transform,
    },
    autoFloats: {
      vertical: vAutoFloat,
      horizontal: hAutoFloat,
    },
  };
};
