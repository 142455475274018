import React, { useCallback, useEffect, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import LoginView from "./views/LoginView/LoginView";
import TokenLoginView from "./views/LoginView/TokenLoginView";
import AppBarLoader from "components/Loaders/AppBarLoader";

import ModalProvider from "components/Modals/ModalProvider";
import NotificationsProvider from "components/Notifications/NotificationsProvider";
import TooltipProvider from "components/Tooltips/TooltipProvider";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "reducers/auth";
import useCurrentUser from "components/hooks/useCurrentUser";
import { LoginService } from "services";
import { clearStudentCourses } from "reducers/primary";

let DragDropBackend = HTML5Backend;
if (
  "ontouchstart" in window ||
  navigator.MaxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0
) {
  DragDropBackend = TouchBackend({
    enableTouchEvents: true,
    enableMouseEvents: true,
  });
}

const LoadablePrimaryView = loadable(
  () => import("./views/PrimaryView/PrimaryView.js"),
  { fallback: <AppBarLoader /> }
);

const LoadableTeacherView = loadable(
  () => import("./views/TeacherView/TeacherView.js"),
  { fallback: <AppBarLoader /> }
);

const LoadableAdminView = loadable(
  () => import("./views/AdminView/AdminView.js"),
  { fallback: <AppBarLoader /> }
);

window.addEventListener("touchstart", function () {
  window.isTouch = true;
});

const Index = (props) => {
  const user = useCurrentUser();

  if (user && user.PrimaryRole) {
    const url = LoginService.getLoginPath(user.PrimaryRole);
    if (url) {
      return <Navigate replace to={url} />;
    }
  }

  return <Navigate replace to="login" />;
};

const Logout = (props) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();

  const navigateTo = useMemo(() => {
    let expires = new Date(0);
    let domain = document.domain;
    let oldDomain = "";

    document.cookie = "Authorization=;path=/;expires=" + expires;

    do {
      document.cookie =
        "Authorization=;path=/;expires=" + expires + ";domain=" + domain;
      document.cookie =
        "Authorization=;path=/;expires=" + expires + ";domain=." + domain;
      oldDomain = domain;
      domain = domain.replace(/^[^.]+\./, "");
    } while (domain !== oldDomain);

    dispatch(clearStudentCourses());

    let cloudRef = localStorage.getItem("cloud-referrer");
    let retVal = null;
    if (cloudRef) {
      localStorage.removeItem("cloud-referrer");
      window.location.assign(cloudRef);
    } else {
      retVal = <Navigate to="/" />;
    }

    sessionStorage.clear();
    setTimeout(() => {
      window.dispatchEvent(new Event("login_token_updated"));
    }, 0);

    return retVal;
  }, [dispatch]);

  return user === false ? navigateTo : null;
};

const App = (props) => {
  const dispatch = useDispatch();

  const user = useCurrentUser();

  const loadCurrentUser = useCallback(async () => {
    await dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEffect(() => {
    window.addEventListener("login_token_updated", loadCurrentUser);

    return () =>
      window.removeEventListener("login_token_updated", loadCurrentUser);
  }, [loadCurrentUser]);

  if (user === null) {
    return null;
  }

  return (
    <DndProvider backend={DragDropBackend}>
      <NotificationsProvider>
        <TooltipProvider>
          <ModalProvider>
            <Routes>
              <Route path="primary/*" element={<LoadablePrimaryView />} />
              <Route path="teacher/*" element={<LoadableTeacherView />} />
              <Route path="admin/*" element={<LoadableAdminView />} />
              <Route path="login" element={<LoginView />} />
              <Route path="tokenlogin" element={<TokenLoginView />} />
              <Route path="logout" element={<Logout />} />
              <Route index element={<Index />} />
            </Routes>
          </ModalProvider>
        </TooltipProvider>
      </NotificationsProvider>
    </DndProvider>
  );
};

export default App;
