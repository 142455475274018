import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import LoginService from "services/LoginService";

const TokenLogin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const f = async () => {
      let search = new URLSearchParams(location.search);
      let token = search.get("token");
      let success = false;

      try {
        success = await LoginService.tokenLogin(token);
      } catch (e) {
        console.error(e.message, e);
      }

      if (success) {
        return navigate("/", { replace: true });
      } else {
        console.log("Bad Token");
      }
    };
    f();
  }, [location.search, navigate]);

  return <div />;
};

export default TokenLogin;
