import FS from '../FetchService';

// // Curriculum Schools
// Route::get('/curricula/{id1}/schools', 'CurriculumController@getSchools');

export default class CurriculumService {
  static async fetch() {
    return await FS.get('/curricula');
  }

  static async get(CurriculumID) {
    return await FS.get(`/curricula/${CurriculumID}`);
  }

  static async save(curriculum) {
    let route = '/curricula';
    let method = curriculum.CurriculumID ? 'patch' : 'post';
    if (curriculum.CurriculumID) {
      route += `/${curriculum.CurriculumID}`;
    }
    return FS[method](route, JSON.stringify(curriculum), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static async delete(CurriculumID) {
    return await FS.delete(`/curricula/${CurriculumID}`);
  }

  static async pageSchools(params, { CurriculumID }) {
    return await FS.get(`/curricula/${CurriculumID}/schools?${params}`);
  }
}