import CountryService from 'services/administration/CountryService';

const initialState = [];

const HYDRATE_COUNTRIES = '@countries/HYDRATE_COUNTRIES';

export const hydrateCountries = (countries) => ({
  type: HYDRATE_COUNTRIES,
  payload: countries,
});

export const getCountries = () => async (dispatch) => {
  try {
    // UNCOMMENT TO ENABLE CACHEING
    // let countries = localStorage.getItem('countries');
    // if (!countries) {
    //   countries = await CountryService.page(`limit=0`);
    //   localStorage.setItem('countries', JSON.stringify(countries));
    // } else {
    //   countries = JSON.parse(countries);
    // }

    // COMMENT OUT TO ENABLE CACHEING
    const countries = await CountryService.page(`limit=0`);

    dispatch(
      hydrateCountries(
        countries.map((c) => ({
          value: c.CountryID,
          text: c.Name,
        }))
      )
    );
  } catch (e) {
    console.error(e.message);
  }
};

const countries = function (state = initialState, action) {
  if (action.type === HYDRATE_COUNTRIES) {
    state = action.payload;
  }

  return state;
};

export default countries;