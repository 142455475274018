import FS from '../FetchService';

export default class TagService {
  static structure = { idName: "TagID" };

  static async page(params) {
    return FS.get(`/tags?${params}`);
  }

  static async getByIDs(tagIDs) {
    const queryParams = new URLSearchParams();
    queryParams.append("limit", 0);
    if (Array.isArray(tagIDs)) {
      for (const tID of tagIDs) {
        queryParams.append("q_TagID[]", tID);
      }
    } else {
      queryParams.append("q_TagID[]", tagIDs);
    }

    return await FS.get(`/tags?${queryParams}`);
  }

  static async create(tag) {
    return FS.post(`/tags`, JSON.stringify(tag), {
      header: (headers) => headers.append("Content-Type", "application/json"),
    });
  }
}