import React from 'react';
import styled from 'styled-components';

import LoginStyleService from 'services/login/LoginStyleService';

import Button from 'components/Button/Button';
import { Chalkboard, ChalkboardText } from 'components/ChalkBoard/ChalkBoard';
import ChalkboardInput from 'components/Input/ChalkboardInput';
import Field from 'components/Field/Field';
import Label from 'components/Label/Label';
import media from 'components/media/media';

import { QuickLogin, QuickLoginWrapper } from './QuickLogin';

const Center = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  ${ ({background}) => `background-image: url(${background})`};
  background-size: cover;
`;

const Section = styled.div`
  padding: 15px 0;
  width: 100%;
`;

const LogoWrap = styled.div`
  position: absolute;
  padding-bottom: 16px;
  padding-top: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;
LogoWrap.displayName = 'LogoWrap';
const Logo = styled.img`
  width: 15vw;
  box-shadow: 0 0 0 0 black;
  ${media.portrait`
    width: 25vw;
  `}
  ${media.mobile`
    width: 40vw;
  `}
`;
Logo.displayName = 'Logo';
Logo.defaultProps = { src: require('assets/general/logo.svg') };

const ChalkboardLayout = (props) => {
  return (
    <Center background={props.background}>
      <Chalkboard style={{ zIndex: 3 }}>
        <Section>
          <ChalkboardText>Tusitawi</ChalkboardText>
        </Section>

        <QuickLoginWrapper>
          {props.quickLogins.map(({ Avatar, FirstName, UserID }, i) => (
            <QuickLogin
              key={UserID}
              avatar={LoginStyleService.getAvatarPath(Avatar)}
              onClick={props.onQuickLogin(UserID)}
            >
              {FirstName}
            </QuickLogin>
          ))}
        </QuickLoginWrapper>

        <Section style={{ maxWidth: "256px" }}>
          <form onSubmit={props.onSubmit}>
            <Field>
              <Label chalkboard center>
                Username
              </Label>
              <ChalkboardInput
                autoFocus
                name="username"
                type="text"
                onChange={props.onChange}
                value={props.username}
              />
            </Field>
            <Field>
              <Label chalkboard center>
                Password
              </Label>
              <ChalkboardInput
                name="password"
                type="password"
                onChange={props.onChange}
                value={props.password}
              />
            </Field>
            {props.error && (
              <Label
                style={{ marginTop: "6px", fontSize: "13pt" }}
                chalkboard
                center
              >
                {props.error}
              </Label>
            )}
            <Field style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ width: "128px", color: "#28483a", fontSize: "25px" }}
                type="submit"
              >
                <i className="fas fa-arrow-right" />
              </Button>
            </Field>
          </form>
        </Section>
      </Chalkboard>
      <LogoWrap>
        <Logo />
      </LogoWrap>
    </Center>
  );
};

export default ChalkboardLayout;