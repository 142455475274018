import React, { useState, useEffect, useCallback } from "react";

import LoginService from "services/LoginService";
import LoginStyleService from "services/login/LoginStyleService";

import ChalkboardLayout from "./ChalkboardLayout";
import LeftLayout from "./LeftLayout";
import { useNavigate } from "react-router";

const ACTIVE = true;

const Login = (props) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    username: "",
    password: "",
    quickLogins: [],
    background: null,
    layout: null,
  });

  useEffect(() => {
    (async () => {
      const layout = await LoginStyleService.getLayout();
      const background = await LoginStyleService.getBackgroundPath();
      const clientLogo = await LoginStyleService.getLogoPath();

      try {
        const quickLogins = await LoginService.getQuickLogins(ACTIVE);

        setState((orig) => ({
          ...orig,
          quickLogins,
          layout,
          background,
          clientLogo,
        }));
      } catch (e) {
        console.error(e.message, e);
      }
    })();
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setState((orig) => ({ ...orig, [name]: value, error: "" }));
  }, []);

  const handleRedirect = useCallback(() => {
    navigate("/");
    // let url = LoginService.getLoginPath(role);
    // if (!!url) {
    //   navigate(url);
    // }
  }, [navigate]);

  const handleQuickLogin = useCallback(
    (UserID) => async (e) => {
      try {
        if (await LoginService.quickLogin(UserID)) {
          handleRedirect();
        }
      } catch (e) {
        console.error(e.message, e);
      }
    },
    [handleRedirect]
  );

  const login = useCallback(async () => {
    try {
      if (state.username && state.password) {
        if (await LoginService.login(state.username, state.password)) {
          handleRedirect();
        } else {
          setState((orig) => ({
            ...orig,
            error: "Incorrect username and/or password",
            password: "",
          }));
        }
      } else {
        setState((orig) => ({
          ...orig,
          error: "Please enter both a username and password",
        }));
      }
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  }, [handleRedirect, state.username, state.password]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      login();
    },
    [login]
  );

  const renderChalkboardLayout = () => {
    return (
      <ChalkboardLayout
        username={state.username}
        password={state.password}
        quickLogins={state.quickLogins}
        error={state.error}
        onQuickLogin={handleQuickLogin}
        onSubmit={handleSubmit}
        onChange={handleChange}
        background={state.background}
      />
    );
  };

  const renderLeftLayout = () => {
    return (
      <LeftLayout
        username={state.username}
        password={state.password}
        quickLogins={state.quickLogins}
        error={state.error}
        onQuickLogin={handleQuickLogin}
        onSubmit={handleSubmit}
        onChange={handleChange}
        background={state.background}
        clientLogo={state.clientLogo}
      />
    );
  };

  const layout = state.layout;
  if (layout === "chalkboard") {
    return renderChalkboardLayout();
  } else if (layout === "left") {
    return renderLeftLayout();
  } else {
    return <span>Loading...</span>;
  }
};

export default Login;
