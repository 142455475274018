import styled from 'styled-components';

const evaluateTextSize = ({ size, isTitle, subTitle, cardSubtitle }) => {
  if (size) return size;
  if (cardSubtitle) return '28px';
  if (isTitle) return '56px';
  if (subTitle) return '28px';
  return '16px';
};

const evaluateFontWeight = ({ isTitle, bold }) => {
  if (isTitle || bold) return 'bold';
  return 'unset';
}

const evaluateColor = ({ color, grey, cardSubtitle }) => {
  if (cardSubtitle) return '#0A74A0';
  if (grey) return '#95989A';
  return color;
};

const Text = styled.span`
  transition: all 0.1s ease-in-out;
  font-size: ${evaluateTextSize};
  font-family: Roboto;
  font-weight: ${evaluateFontWeight};
  color: ${evaluateColor};
`;

Text.defaultProps = {
  color: 'black'
};

export default Text;