import FS from 'services/FetchService';

/**
 * Typical redux middleware signature.
 *
 * See: https://redux.js.org/advanced/middleware#the-final-approach
 */

// Only these reducer action type prefixes will be logged.
const typeWhitelist = [
  "@@router",
  "@calculator/SET_VISIBILITY",
  "@dictionary/SET_VISIBILITY",
];

const logger = (store) => (next) => (action) => {
  if (
    typeof action !== "function" &&
    typeWhitelist.some((t) => !!action.type.match(new RegExp(t)))
  ) {
    if (sessionStorage.getItem("token")) {
      FS.post(
        "/log",
        JSON.stringify({
          Type: action.type,
          Payload: JSON.stringify(action.payload),
        }),
        {
          header: (h) => h.append("Content-Type", "application/json"),
          response: "text",
        }
      );
    }
  }
  let result = next(action);
  return result;
};

export default logger;