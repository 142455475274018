import { useSelector } from "react-redux";

export const UserHierarchy = [
  "SuperAdmin",
  "SchoolAdmin",
  "Teacher",
  "Student",
  "QuickUserLogin",
];

const useCurrentUser = () => {
  const user = useSelector((s) => s.auth.user);

  // if (!role) {
  //   sessionStorage.clear();
  //   window.location.pathname = "/login";
  // }

  return user;
};

export default useCurrentUser;
