import React, { useState, useEffect } from "react";
import styled from "styled-components";
import media, { sizes } from "components/media/media";
import NavBarItem from "./NavBarItem";

const hamburger = require("assets/primary-interface/poop/navbar/hamburger.svg");

const NAVBAR_HEIGHT = "72px";
export const MOBILE_NAVBAR_HEIGHT = "56px";
const SLIDER_WIDTH = "170px";

const Slider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-x: hidden;
  background-color: #303030;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s linear;

  pointer-events: ${({ expanded }) => (expanded ? "auto" : "none")};

  background-color: ${({ expanded }) =>
    expanded ? "rgba(0,0,0,0.4)" : "transparent"};
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  overflow: visible;
  max-width: 100vw;
  visibility: visible;

  transition: transform 0.3s ease-in-out;

  // this line changes the size of the slider burger menu
  transform: translateX(
    ${({ expanded, iconModeNavBar }) => {
      if (expanded && iconModeNavBar) {
        return SLIDER_WIDTH;
      } else if (expanded) {
        return "200px";
      } else {
        return 0;
      }
    }}
  );
  box-shadow: ${({ expanded }) =>
    expanded ? "0px 0px 20px 3px #232323" : "none"};
`;

const Bar = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #303030;

  height: ${NAVBAR_HEIGHT};
  ${media.portrait`
    height: ${MOBILE_NAVBAR_HEIGHT};
    padding: 8px;
  `}
  width: 100%;
`;

const Items = styled.div`
  height: 100%;
  display: flex;
`;

const Children = styled.div`
  position: absolute;
  top: ${NAVBAR_HEIGHT};
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  ${media.portrait`
    top: ${MOBILE_NAVBAR_HEIGHT};
  `}
`;

const SliderItems = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${({ iconModeNavBar }) => {
    if (iconModeNavBar) {
      return SLIDER_WIDTH;
    } else {
      return "200px";
    }
  }};

  overflow-y: auto;
  visibility: ${({ expanded }) => (expanded ? "visible" : "hidden")};
`;

const mediaQueries = {
  isLandscape: matchMedia(`(max-width: ${sizes.landscape}px)`),
  isPortrait: matchMedia(`(max-width: ${sizes.portrait}px)`),
  isMobile: matchMedia(`(max-width: ${sizes.mobile}px)`),
};

const getMode = () => {
  if (mediaQueries.isMobile.matches) {
    return "mobile";
  } else if (mediaQueries.isPortrait.matches) {
    return "portrait";
  } else if (mediaQueries.isLandscape.matches) {
    return "landscape";
  } else {
    return "desktop";
  }
};

const NavBar = (props) => {
  const [state, setState] = useState({
    mode: getMode(),
    expanded: false,
  });

  const handleMediaChange = () => {
    const mode = getMode();
    if (mode === "landscape" || mode === "desktop") {
      setState((orig) => ({ ...orig, expanded: false, mode }));
    } else {
      setState((orig) => ({ ...orig, mode }));
    }
  };

  useEffect(() => {
    Object.keys(mediaQueries).forEach((k) => {
      mediaQueries[k].addListener(handleMediaChange);
    });

    return () => {
      Object.keys(mediaQueries).forEach((k) => {
        mediaQueries[k].removeListener(handleMediaChange);
      });
    };
  }, []);

  const toggleExpand = () => {
    setState({ expanded: !state.expanded });
  };

  const handleCollapse = (e) => {
    if (state.expanded) {
      e.preventDefault();
      e.stopPropagation();
      setState((orig) => ({ ...orig, expanded: false }));
    }
  };

  const evaluateItem = (expanderItems) => (item, i) => {
    const toRemove = [];

    if (mediaQueries.isLandscape.matches) {
      toRemove.push("landscape");
    }
    if (mediaQueries.isPortrait.matches) {
      toRemove.push("portrait");
    }
    if (mediaQueries.isMobile.matches) {
      toRemove.push("mobile");
    }

    if (toRemove.indexOf(item.props.collapse) !== -1) {
      if (item.props.items) {
        item.props.items.forEach((item, j) => {
          expanderItems.push(
            <NavBarItem
              side={true}
              key={item.title || item.icon}
              icon={item.icon}
              to={item.to}
              onClick={item.onClick}
              title={item.title}
              className={item.className}
              tourExpanded={item.tourExpanded}
              iconMode={item.iconMode}
              userIcon={item.userIcon}
              tutorialVideos={item.tutorialVideos}
            />
          );
        });
      } else {
        expanderItems.push(
          <NavBarItem
            {...item.props}
            key={item.props.title || item.props.icon}
            title={item.props.title || item.props.collapsedTitle}
            side={true}
            iconMode={item.props.iconMode}
            userIcon={item.props.userIcon}
          />
        );
      }
      return null;
    }

    return item;
  };

  const expanded =
    props.tourExpanded == null ? state.expanded : props.tourExpanded;

  const { iconModeNavBar } = props;

  let expanderItems = [];

  const left = React.Children.map(props.left, evaluateItem(expanderItems));

  const right = React.Children.map(props.right, evaluateItem(expanderItems));

  return (
    <Slider>
      <SliderItems
        iconModeNavBar={iconModeNavBar}
        onClick={handleCollapse}
        expanded={expanded}
      >
        {expanderItems}
      </SliderItems>
      <Container
        iconModeNavBar={iconModeNavBar}
        expanded={expanded}
        className="stuff"
      >
        <Bar>
          <Items>
            {expanderItems.length ? (
              <NavBarItem
                id="navbar-menu"
                icon={hamburger}
                onClick={toggleExpand}
              />
            ) : null}
            {left}
          </Items>
          {props.centreDisplay && (
            <Items>
              <NavBarItem title={props.centreDisplay} displayOnly />
            </Items>
          )}
          <Items>{right}</Items>
        </Bar>
        <Children>{props.children}</Children>
        <Cover id="cover" onClick={handleCollapse} expanded={expanded} />
      </Container>
    </Slider>
  );
};

export default NavBar;
