import FS from '../FetchService';
import get from 'lodash/get';

// // Schools
// Route::get('/schools', 'SchoolController@getSchools');
// Route::get('/schools/{id}', 'SchoolController@getSchool');
// Route::post('/schools', 'SchoolController@createSchool');
// Route::patch('/schools/{id}', 'SchoolController@updateSchool');
// Route::delete('/schools/{id}', 'SchoolController@deleteSchool');

export default class SchoolService {
  static async page(params, { CurriculumID }) {
    return FS.get(`/curricula/${CurriculumID}/schools?${params}`);
  }
  static async get({ SchoolID, role }) {
    let route =
      role === "SuperAdmin" ? `/schools/${SchoolID}` : `/current_user/school`;
    let school = await FS.get(route);
    school.CountryID = get(school, "Country.CountryID", "");
    return school;
  }

  static async save({ school, role }) {
    let route = `/schools`;
    let method = school.SchoolID ? "patch" : "post";
    if (school.SchoolID) {
      route += `/${school.SchoolID}`;
    }
    if (role === "SchoolAdmin") {
      method = "post";
      route = "/current_user/school";
    }
    return FS[method](route, JSON.stringify(school), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async fetch({ role }) {
    if (role === "SuperAdmin") {
      return await FS.get("/schools?limit=0");
    } else if (role === "SchoolAdmin") {
      return [await FS.get("/current_user/school")];
    } else {
      throw new Error("U cant do dis");
    }
  }
}