import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

// TODO: don't transform icons here, just use transformed icons
const IconContainer = styled.div`
  display: flex;
  align-content: center;
  margin: auto;

  width: 45%;

  // width: 30px;
  // display: flex;
  // justify-content: space-between;
`;

const Icon = styled.img`
  margin: auto;

  width: ${({ tutorial }) => (tutorial ? "100%" : "30px")};

  ${({ flipX }) => flipX && "transform: scaleX(-1);"}
`;

const TitleContainer = styled.div`
  margin: auto;

  width: 45%;

  // width: calc(100% - 30px);
  // padding-left: 10px;
`;

const NavbarIcon = (props) => {
  const { title, icon, flipX, tutorialVideos } = props;

  return (
    <Wrapper>
      <IconContainer>
        <Icon src={icon} flipX={flipX} tutorial={tutorialVideos} />
      </IconContainer>
      <TitleContainer>{title}</TitleContainer>
    </Wrapper>
  );
};

export default NavbarIcon;