import FS from '../FetchService';

export default class ImageService {
  static async convertDataURIToFile(dataURI) {
    const request = await fetch(dataURI);
    const buffer = await request.arrayBuffer();
    return new File([buffer], 'image.png', { type: 'image/png' });
  }

  static async uploadDataURI(dataURI) {
    try {
      const file = await ImageService.convertDataURIToFile(dataURI);
      return await FS.post(`/images`, FS.jsonToForm({
        image: file
      }), { response: 'text' });
    } catch (e) {
      console.error(e.message);
      console.error(e);
    }
  }

}
