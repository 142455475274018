import React, { createContext, useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import ConfirmationDialog from "./ConfirmationDialog";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;

  overflow-y: auto;

  transition: all 0.2s linear;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;

  transition: background-color 0.2s linear, opacity 0.2s linear;
  opacity: ${({ open }) => (open ? "1" : "0")};
  pointer-events: ${({ open }) => (open ? "unset" : "none")};
  background-color: ${({ open }) =>
    open ? "rgba(0,0,0,0.25)" : "rgba(0,0,0,0)"};
  & > div {
    transition: transform 0.2s ease-in-out;
    transform: scale(${({ open }) => (open ? "1" : "0.9")});
  }
  user-select: none;
`;

export const ModalContext = createContext({});

const ModalProvider = (props) => {
  const timeoutRef = useRef();

  const [state, setState] = useState({
    open: false,
    component: undefined,
    modalProps: undefined,
    options: {},
  });

  const handleKeyUp = useCallback((e) => {
    if (e.which === 27 && state.open) { // only call closeModal() when a modal is open to prevent rerender on every escape key press
      closeModal();
    }
  }, [state.open]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  });

  const confirm = (message, props = {}) =>
    new Promise((resolve, reject) => {
      openModal(
        ConfirmationDialog,
        {
          message,
          ...props,
          confirm: (value) => {
            closeModal();
            resolve(value);
          },
          cancel: () => {
            closeModal();
            reject();
          },
        },
        { noOutsideClick: true }
      );
    });

  const openModal = (component, props, options) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    setState((orig) => ({
      ...orig,
      open: true,
      component: component,
      modalProps: props,
      options: options || {},
    }));
  };

  const closeModal = () => {
    setState((orig) => ({
      ...orig,
      open: false,
    }));

    timeoutRef.current = setTimeout(() => {
      setState((orig) => ({
        ...orig,
        component: undefined,
        modalProps: undefined,
      }));
    }, 200);
  };

  const setModalContent = (component, props, options) => {
    setState((orig) => ({
      ...orig,
      component: component,
      modalProps: props,
    }));
  };

  const handleOverlayClicked = () => {
    if (state.open && !state.options.noOutsideClick) {
      closeModal();
    }
  };

  const diffuseClick = (e) => {
    e.stopPropagation();
  };

  const { component: ModalContent, modalProps, open } = state;
  return (
    <ModalContext.Provider
      value={{
        openModal: (component, props, options) =>
          openModal(component, props, options),
        closeModal: () => closeModal(),
        setModalContent: (component, props) =>
          setModalContent(component, props),
        confirm: (message, props) => confirm(message, props),
        prompt: (message, props = {}) =>
          confirm(message, { input: true, ...props }),
        isModalOpen: state.open,
      }}
    >
      <Wrapper>
        <Overlay open={open}>{props.children}</Overlay>
        <Modal onClick={handleOverlayClicked} open={open}>
          {ModalContent && (
            <ModalContent {...modalProps} onClick={diffuseClick} />
          )}
        </Modal>
      </Wrapper>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
