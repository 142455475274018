import FS from '../FetchService';

export default class SubjectService {
  static structure = { idName: "SubjectID" };

  static async page(params) {
    return FS.get(`/subjects?${params}`);
  }

  static async create(subject) {
    return FS.post(`/subjects`, JSON.stringify(subject), {
      header: (headers) => headers.append("Content-Type", "application/json"),
    });
  }
}