import update from 'immutability-helper';
import CurriculumService from 'services/administration/CurriculumService';
import CurriculumCourseGroupService from 'services/administration/CurriculumCourseGroupService';

import merge_records from 'utils/merge_records';
import CurriculumSectionService from 'services/administration/CurriculumSectionService';

const initialState = {
  curriculums: [],
  groups: [],
  codes: [],
  sections: []
};

export const UPDATE_CURRICULUMS = '@curriculum/UPDATE_CURRICULUMS';
export const UPDATE_CURRICULUM = '@curriculum/UPDATE_CURRICULUM';
export const HYDRATE_CURRICULUM_GROUPS = '@curriculum/HYDRATE_CURRICULUM_GROUPS';
export const UPDATE_CURRICULUM_GROUP = '@curriculum/UPDATE_CURRICULUM_GROUP';
export const UPDATE_CURRICULUM_SECTIONS = '@curriculum/UPDATE_CURRICULUM_SECTIONS';
export const REMOVE_CURRICULUM_SECTION = '@curriculum/REMOVE_CURRICULUM_SECTION';

const curriculum = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRICULUMS:
      return update(state, { curriculums: { $set: action.payload } });

    case UPDATE_CURRICULUM: {
      return update(state, {
        curriculums: merge_records([action.payload], "CurriculumID"),
      });
    }

    case HYDRATE_CURRICULUM_GROUPS: {
      return update(state, {
        groups: merge_records(action.payload, "CurriculumCourseGroupID"),
      });
    }

    case UPDATE_CURRICULUM_GROUP: {
      return update(state, {
        groups: merge_records([action.payload], "CurriculumCourseGroupID"),
      });
    }

    case UPDATE_CURRICULUM_SECTIONS: {
      return update(state, {
        sections: merge_records(action.payload, "CurriculumSectionID"),
      });
    }

    case REMOVE_CURRICULUM_SECTION: {
      let index = state.sections
        .map((s) => s.CurriculumSectionID)
        .indexOf(action.payload);
      return update(state, {
        sections: { $splice: [[index, 1]] },
      });
    }

    default:
      return state;
  }
};

export default curriculum;

/**
 * Synchronous Action Creators
 */
export const updateCurriculums = curriculums => ({
  type: UPDATE_CURRICULUMS,
  payload: curriculums
});

export const updateCurriculum = curriculum => ({
  type: UPDATE_CURRICULUM,
  payload: curriculum
});

export const hydrateCurriculumGroups = (groups) => ({
  type: HYDRATE_CURRICULUM_GROUPS,
  payload: groups
});

export const updateCurriculumCourseGroup = (curriculumCourseGroup) => ({
  type: UPDATE_CURRICULUM_GROUP,
  payload: curriculumCourseGroup
});

export const updateCurriculumSections = (curriculumSections) => ({
  type: UPDATE_CURRICULUM_SECTIONS,
  payload: curriculumSections
});

export const removeCurriculumSection = (CurriculumSectionID) => ({
  type: REMOVE_CURRICULUM_SECTION,
  payload: CurriculumSectionID
});

/**
 * Asynchronous Action Creators
 */
export const getCurriculums = () => async dispatch => {
  try {
    dispatch(updateCurriculums(await CurriculumService.fetch()));
  } catch (e) {
    console.error(e);
  }
};

export const getCurriculumCourseGroups = (params) => async dispatch => {
  try {
    dispatch(hydrateCurriculumGroups(
      await CurriculumCourseGroupService.fetch(null, params)
    ));
  } catch (e) {
    console.error(e);
  }
};

export const getCurriculumSections = (params, role) => async (dispatch) => {
  try {
    dispatch(
      updateCurriculumSections(
        await CurriculumSectionService.fetch({ ...params, role })
      )
    );
  } catch (e) {
    console.error(e);
  }
};

export const saveCurriculum = (curriculum) => async dispatch => {
  try {
    const c = await CurriculumService.save(curriculum);
    dispatch(updateCurriculum(c));
    return c;
  } catch (e) {
    console.error(e);
  }
};

export const saveCurriculumCourseGroup = (curriculumCourseGroup, params) => async dispatch => {
  try {
    const ccg = await CurriculumCourseGroupService.save(curriculumCourseGroup, params);
    dispatch(updateCurriculumCourseGroup(ccg));
    return ccg;
  } catch (e) {
    console.error(e);
  }
};

export const saveCurriculumSection = (curriculumSection, params) => async dispatch => {
  try {
    const cs = await CurriculumSectionService.save(curriculumSection, params);
    dispatch(updateCurriculumSections([cs]));
    return cs;
  } catch (e) {
    console.error(e);
  }
};