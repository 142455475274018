import React from 'react';
import styled from 'styled-components';

const NButton = styled.button`
  cursor: pointer;
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-tap-highlight-color: transparent;
`;
NButton.displayName = 'NButton';

const Button = (props) => {
  return <NButton {...props} />;
};

export default Button;