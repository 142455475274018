import FS from './FetchService';
import UserService from './users/UserService';
import isObject from 'lodash/isObject';

export default class LoginService {
  static async getCurrentUser() {
    try {
      const result = await FS.get("/current_user");
      return result;
    } catch (e) {
      return null;
    }
  }

  static async getQuickLogins(props) {
    if (isObject(props)) {
      return UserService.page(`q_Roles_Name=QuickUserLogin&limit=0`, props);
    } else {
      return FS.get("/login");
    }
  }

  static async login(username, password) {
    const form = new FormData();
    form.append("Login", username);
    form.append("Password", password);
    const result = await FS.post("/login", form);
    if (result.ok) {
      sessionStorage.setItem("token", result.token);
      window.dispatchEvent(new Event("login_token_updated"));
      if (result.renew_password) {
        sessionStorage.setItem("renew_password", true);
      }
      return true;
    }
  }

  static async quickLogin(ql) {
    const form = new FormData();
    form.append("UserID", ql);
    const result = await FS.post("/login", form);
    if (result.ok) {
      sessionStorage.setItem("token", result.token);
      window.dispatchEvent(new Event("login_token_updated"));
      return true;
    }
  }

  static async tokenLogin(token) {
    const form = new FormData();
    form.append("Token", token);
    const result = await FS.post("/tokenlogin", form);

    if (result.ok) {
      sessionStorage.setItem("token", result.token);
      window.dispatchEvent(new Event("login_token_updated"));
      localStorage.setItem("cloud-referrer", document.referrer);
      return true;
    }
    return false;
  }

  static getLoginPath(role) {
    switch (role) {
      case "SuperAdmin":
      case "SchoolAdmin":
        return "/admin";
      case "Teacher":
        return "/teacher";
      case "CloudStudent":
      case "QuickUserLogin":
      case "Student":
        let params = new URLSearchParams();
        ["Textbook", "Video", "Audio", "WebApp", "Worksheet"].forEach((v) =>
          params.append("q_MediaType_Name[]", v)
        );
        const url = `/primary?${params}`;
        return url;
      default:
        return false;
    }
  }
}
