import styled from 'styled-components';
import media from 'components/media/media';

export const QuickLoginWrapper = styled.div`
  display: flex;
  width: 100%;

  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-wrap: wrap;
`;

export const QuickLogin = styled.div`
  cursor: pointer;
  transition: all 0.1s;
  border-radius: 10px;
  -webkit-tap-highlight-color: transparent;

  width: ${({layout}) => (layout === 'left' ? '85px' : '5.908vw')};
  min-width: 75px;
  height: ${({layout}) => (layout === 'left' ? '140px' : '150px')};
  margin: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-family: Roboto;
  color: #c8c8c8;
  font-size: 16px;

  ${media.landscape`
    width: 6.5vw;
    height: 120px;
  `}

  ${media.mobile`
    width: 75px;
    height: 110px;
  `}

  &:hover {
    background-color: rgba(0,0,0,0.2);
    transform: scale(1.03);
  }

  &::before {
    content: '';

    background-image: url('${({ avatar }) => avatar}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    width: 100%;
    padding-bottom: 100%;

    margin-bottom: 5px;
  }
`;
