import FS from '../FetchService';

export default class UserService {
  static getUrlPrefix(SchoolID, role) {
    if (role === 'SuperAdmin') {
      return `/schools/${SchoolID}`;
    } else {
      return `/current_user/school`;
    }
  }

  static async page(params, { SchoolID, role }) {
    const pre = this.getUrlPrefix(SchoolID, role);
    let route = SchoolID ? `${pre}/users` : '/users';
    if (params) {
      route = `${route}?${params}`;
    }
    return await FS.get(route);
  }

  static async delete(UserID, { SchoolID, role }) {
    const pre = this.getUrlPrefix(SchoolID, role);
    return FS.delete(`${pre}/users/${UserID}`);
  }

  static async save(user, { SchoolID, role }) {
    const pre = this.getUrlPrefix(SchoolID, role);

    let route = SchoolID ? `${pre}/users` : '/users';
    let method = user.UserID ? 'patch' : 'post';
    if (user.UserID) {
      route += `/${user.UserID}`;
    }
    if (user.Roles) {
      if (user.Roles[0].RoleID) {
        user.Roles = user.Roles.map(r => r.Name);
      }
    }
    return await FS[method](route, JSON.stringify(user), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static async bulkUpload(file, { SchoolID }) {
    const route = `/schools/${SchoolID}/users/cloud_csv`;
    return await FS.post(route, FS.jsonToForm({ students: file }));
  }

  static validateUserFields(user, checkForPassword) {
    const result = {};
    ['FirstName', 'LastName', 'Login', ...(checkForPassword ? ['Password'] : [])].forEach(k => {
      if (!user[k]) {
        result[k] = 'Required';
      }
    });
    return result;
  }

  static async changePassword(OldPassword, NewPassword) {
    return await FS.patch('/current_user/password', JSON.stringify({ OldPassword, NewPassword }));
  }

  static async changeUserPassword(userID, NewPassword, { SchoolID, role }) {
    const pre = this.getUrlPrefix(SchoolID, role);
    return await FS.patch(`${pre}/users/${userID}/password`, JSON.stringify({ NewPassword }));
  }
}
