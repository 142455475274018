import update from 'immutability-helper';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';

/**
 * merge_records is used to merge / shake an array of records with IDs into
 * an old array / cached version of said records, without source mutation.
 * 
 * That doens't make much sense.
 * 
 * Here's an example I guess
 * 
 * var old = [{ id: 1, name: 'chicken' }, { id: 2, name: 'pickles' }];
 * var new = [{ id: 1, name: 'ralph' }, { id: 3, name: 'turkeys' }];
 * 
 * var merged = merge_records(new, 'id')(old);
 * // -> [{ id: 1, name: 'ralph' }, { id: 2, name: 'pickles' }, { id: 3, name: 'turkeys' }];
 */

const merge_records =
  (newArr, IDkey) =>
  (old = []) => {
    let update_operation = { $push: [] };
    newArr.forEach((obj) => {
      let index = indexOf(old, find(old, { [IDkey]: obj[IDkey] }));
      if (index === -1) {
        update_operation.$push.push(obj);
      } else {
        update_operation[index] = { $merge: obj };
      }
    });
    if (!update_operation.$push.length) {
      delete update_operation.$push;
    }
    return update(old, update_operation);
  };

export default merge_records;