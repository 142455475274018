import React, { forwardRef } from "react";
import styled from "styled-components";

let active =
  (yes, no) =>
  ({ focused, active }) =>
    focused || active ? yes : no;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #95989a;
  border-color: ${({ focused }) => (focused ? "#0A74A0" : "#95989A")};
  border-color: ${({ error }) => (error ? "red" : "initial")};
  transition: border-color 0.1s ease-in-out;
  cursor: ${({ onClick }) => (!!onClick ? "pointer" : "default")};
  margin-top: ${({ hasLabel }) => (hasLabel ? "24px !important" : "0")};

  min-height: 40px;
  height: ${({ textArea, big }) =>
    (big && "60px") || (textArea && "unset") || "40px"};
  cursor: text;
`;
InputWrapper.displayName = "InputWrapper";

const InputLabel = styled.label`
  transition: all 0.2s ease-in-out;
  position: absolute;
  left: 1px;
  top: ${active("-12px", "8px")};
  pointer-events: none;

  white-space: nowrap;
  font-size: ${({ big }) => active("12px", big ? "40px" : "20px")};
  font-family: Roboto;
  color: #7d7d7d;
`;

export default forwardRef(({ label, children, style, ...props }, ref) => {
  return (
    <InputWrapper ref={ref} hasLabel={!!label} style={style} {...props}>
      {label && (
        <InputLabel
          focused={props.focused}
          active={props.active}
          big={props.big}
        >
          {label}
        </InputLabel>
      )}
      {children}
    </InputWrapper>
  );
});
