import { combineReducers } from 'redux';

import tools from './tools';
import auth from './auth';
import curriculum from './curriculum';
import school from './school';
import countries from './countries';
import grades from './grades';
import primary from './primary';

export default combineReducers({
  auth,
  curriculum,
  school,
  countries,
  grades,
  primary,
  tools,
});