import React, { useState, useEffect, useRef, useCallback } from "react";

import Modal from "./Modal";
import ShortButton from "components/Button/ShortButton";
import Field from "components/Field/Field";
import Text from "components/Text/Text";
import Input from "components/Input/Input";
import InputArea from "components/Input/InputArea";

const ButtonArea = (dialogType, confirm, cancel) => {
  switch (dialogType) {
    case "IWSConfirm":
      return (
        <Field
          style={{ paddingTop: "32px", justifyContent: "space-around" }}
          inline
        >
          <ShortButton
            style={{ width: "155px", height: "51px" }}
            onClick={cancel}
            secondary
          >
            NO
          </ShortButton>
          <ShortButton
            autoFocus
            style={{ width: "155px", height: "51px" }}
            onClick={confirm}
          >
            YES
          </ShortButton>
        </Field>
      );
    default:
      return (
        <Field style={{ paddingTop: "32px" }} rightAlign>
          <ShortButton
            style={{ marginRight: "16px" }}
            onClick={cancel}
            secondary
          >
            CANCEL
          </ShortButton>
          <ShortButton autoFocus onClick={confirm}>
            OK
          </ShortButton>
        </Field>
      );
  }
};

const ConfirmationDialog = (props) => {
  const input = useRef();

  const { confirm, cancel, value } = props;

  const [state, setState] = useState({
    value,
  });

  useEffect(
    () => input.current && input.current.select && input.current.select(),
    []
  );

  const onConfirm = useCallback(
    () => confirm(state.value),
    [confirm, state.value]
  );

  const handleKeyUp = useCallback(
    (e) => {
      switch (e.which) {
        case 13: // Enter
          e.stopPropagation();
          return onConfirm();
        default:
          return;
      }
    },
    [onConfirm]
  );

  return (
    <Modal
      onKeyUp={handleKeyUp}
      style={{
        maxWidth: !props.type ? "640px" : "unset",
        width: props.longInput ? "100%" : "unset",
      }}
    >
      {props.type ? (
        props.message
      ) : (
        <Field style={{ paddingTop: 0 }}>
          <Text>{props.message}</Text>
        </Field>
      )}
      {props.input && (
        <Field>
          {props.area ? (
            <InputArea
              ref={input}
              onKeyUp={(e) => e.which === 13 && e.stopPropagation()}
              value={state.value}
              onChange={({ target: { value } }) => setState({ value })}
            />
          ) : (
            <Input
              ref={input}
              value={state.value}
              onChange={({ target: { value } }) => setState({ value })}
            />
          )}
        </Field>
      )}
      {ButtonArea(props.type, onConfirm, cancel)}
    </Modal>
  );
};

export default ConfirmationDialog;
