import GradeService from "services/users/GradeService";

const initialState = [];

export const HYDRATE_GRADES = '@grades/HYDRATE_GRADES';

const grades = (state = initialState, action) => {
  if (action.type === HYDRATE_GRADES) {
    state = action.payload;
  }

  return state;
};

export default grades;

export const hydrateGrades = grades => ({ type: HYDRATE_GRADES, payload: grades });
 
export const getGrades = () => async dispatch => {
  try {
    dispatch(
      hydrateGrades(
        (
          await GradeService.fetch(
            new URLSearchParams([
              ["limit", 0],
              ["o", "Value"],
            ])
          )
        ).map((g) => ({ text: g.Name, value: g.GradeID, ...g }))
      )
    );
  } catch (e) {
    console.error('Error: ', e);
  }
}