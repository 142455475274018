import React, { createContext, useRef, useCallback } from "react";
import styled from "styled-components";
import NotificationSystem from "react-notification-system-17";
import isObject from "lodash/isObject";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
Container.displayName = "Container";

export const NotificationsContext = createContext({});

let notifications = {};

const NotificationsProvider = (props) => {
  const notificationsRef = useRef();

  const createNotification = useCallback(
    (level) =>
      (title, message, options = {}) => {
        if (!notificationsRef.current) {
          if (options.stopRecursion) {
            return;
          }
          return setTimeout(
            () =>
              createNotification(level)(title, message, {
                ...options,
                stopRecursion: true,
              }),
            500
          );
        }

        if (isObject(message)) {
          options = message;
          message = title;
          message = undefined;
        } else if (!message) {
          message = title;
          title = undefined;
        }

        notificationsRef.current.addNotification({
          title,
          message,
          level,
          ...options,
        });
      },
    []
  );

  notifications.notifyWarning = useCallback(
    (...args) => createNotification("warning")(...args),
    [createNotification]
  );
  notifications.notifyWarn = useCallback(
    (...args) => createNotification("warning")(...args),
    [createNotification]
  );
  notifications.notifySuccess = useCallback(
    (...args) => createNotification("success")(...args),
    [createNotification]
  );
  notifications.notifyInfo = useCallback(
    (...args) => createNotification("info")(...args),
    [createNotification]
  );
  notifications.notifyError = useCallback(
    (...args) => createNotification("error")(...args),
    [createNotification]
  );

  return (
    <NotificationsContext.Provider value={notifications}>
      <Container>
        {props.children}
        <NotificationSystem ref={notificationsRef} />
      </Container>
    </NotificationsContext.Provider>
  );
};

export { notifications };

export default NotificationsProvider;
