import React from 'react';
import styled from 'styled-components';

const NLabel = styled.label`
  color: ${({ chalkboard }) => chalkboard ? '#c8c8c8' : '#7d7d7d'};
  text-align: ${({ center }) => center ? 'center' : 'left'};
  width: 100%;
  display: block;
  font-size: ${({ chalkboard }) => chalkboard ? '17pt' : '16px'};
  font-family: Roboto;
  padding-bottom: 5px;
`;
NLabel.displayName = 'NLabel';

const Err = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  color: #F15A57;
  margin-top: 4px;
  float: right;
`;
Err.displayName = 'Err';

const Label = ({ children, error, ...rest }) => {
  return (
    <NLabel {...rest}>
      {children}
      {error && <Err>{error}</Err>}
    </NLabel>
  );
};

export default Label;