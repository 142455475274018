import styled from 'styled-components';

const evaluateDisplay = ({ inline, rightAlign }) => {
  if (rightAlign || inline) {
    return 'flex';
  }

  return 'block';
}

const Field = styled.div`
  padding-top: 8px;
  width: 100%;
  
  flex: 1;
  flex-grow: ${({ grow, basis }) => basis ? '0' : grow};
  flex-basis: ${({ basis }) => basis ? basis : '0'};
  display: ${evaluateDisplay};
  justify-content: ${({ rightAlign }) => rightAlign ? 'flex-end' : 'unset'};

  &>div {
    padding: 0 ${({ inline }) => inline ? '16px' : '0'};
  }

  &>div:first-child {
    padding-left: 0;
  }

  &>div:last-child {
    padding-right: 0;
  }
`;
Field.displayName = 'Field';

export default Field;