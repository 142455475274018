import { css } from 'styled-components';

// export const sizes = {
//   mobile: 600,
//   portrait: 900,
//   landscape: 1200,
//   desktop: 1800
// };

export const sizes = {
	mobile: 425,
	portrait: 768,
	landscape: 1200,
	desktop: 1024,
};

export const orientations = {
	mobile: 'portrait',
};

const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${sizes[label] / 16}em) ${label === 'mobile'
				? 'and (orientation: portrait)'
				: null} {
			${css(...args)}
		}
	`;

	return acc;
}, {});

export default media;
