import update from 'immutability-helper';
import StudentService from 'services/primary/StudentService';

const initialState = {
  courses: []
};

export const HYDRATE_STUDENT_COURSES = '@primary/HYDRATE_STUDENT_COURSES';

const primary = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE_STUDENT_COURSES:
      return update(state, { courses: { $set: action.payload } });
    default:
      return state;
  }
};

export default primary;

export const hydrateStudentCourses = courses => ({
  type: HYDRATE_STUDENT_COURSES,
  payload: courses
});

export const getStudentCourses = () => async dispatch => {
  try {
    let courses = await StudentService.getCourses();
    dispatch(hydrateStudentCourses(courses));
  } catch (e) {
    console.error(e);
  }
};

export const clearStudentCourses = () => (dispatch) => {
  dispatch(hydrateStudentCourses(initialState.courses));
};