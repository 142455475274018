import React from 'react';
import styled from 'styled-components';
import media from 'components/media/media';
import { MOBILE_NAVBAR_HEIGHT } from 'components/NavBar/NavBar';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Bar = styled.div`
  width: 100%;
  height: 72px;
  background-color: #303030;

  ${media.portrait`
    height: ${MOBILE_NAVBAR_HEIGHT};
  `}
`;

const AppBarLoader = () => (
  <Container>
    <Bar />
  </Container>
);

export default AppBarLoader;