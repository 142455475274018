import React from 'react';
import styled from 'styled-components';
import media from 'components/media/media';

import Button from 'components/Button/Button';
import Field from 'components/Field/Field';

import { QuickLogin, QuickLoginWrapper } from './QuickLogin';

import LoginStyleService from 'services/login/LoginStyleService';

const Background = styled.div`
  background-image: url('${({background}) => background}');
  background-size: cover;
  height: 100%;
`;

const SideBar = styled.div`
  width:450px;
  height: 100%;
  padding-top: 15px;
  background-color: rgba(80,80,80,0.8);
  text-align: center;
  position: relative;

  ${media.landscape`
    padding-top: 0;
    width: 100%;
    flex-direction: row;
  `}
`;

const BaseSection = styled.div`
  width: 100%;
  font-family: Roboto;
`;

const Title = styled(BaseSection)`
  font-size: 64px;
  height: 82px;
  color: orange;
  font-family: erasdust;
`;

const MainSection = styled(BaseSection)`
  position: absolute;
  top: 112px;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  overflow: auto;

  ${media.landscape`
    top: 82px;
    flex-direction: row;
  `}
`;

const SubSection = styled(BaseSection)`
  position: absolute;

  ${media.landscape`
    width: 50%;
  `}
  ${media.mobile`
    width: 100%;
  `}
`;
const TopSection = styled(SubSection)`
  top: 0;
  bottom: 160px;
  overflow: auto;

  ${media.landscape`
    bottom: 0;
  `}
  ${media.mobile`
    left: unset;
    right: unset;
    bottom: 195px;
  `}
`;
const BottomSection = styled(SubSection)`
  height: 160px;
  bottom: 0;

  ${media.landscape`
    height: unset;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
  `}
  ${media.mobile`
    top: unset;
    right: unset;
    height: 195px;
    display: block;
    align-items: unset;
  `}
`;

const Label = styled.label`
  display: inline-block;
  width: 100px;
  text-align: right;
  color: white;
  padding-right: 0.5em;
`;

const Logo = styled.img`
  position: absolute;
  right: 20px;

  ${media.landscape`
    right: unset;
  `}
`;

const SystemLogo = styled(Logo)`
  background-color: white;
  width: 10%;
  min-width: 100px;
  padding: 7px;
  bottom: 20px;

  ${media.landscape`
    bottom: unset;
    top: 5px;
    right: 5px;
  `}

  ${media.mobile`
    top: unset;
    bottom: 5px;
    right: 5px;
    width: 100px;
  `}
`;
SystemLogo.defaultProps = { src: require('assets/general/logo.svg') };

const ClientLogo = styled(Logo)`
  width: 17%;
  min-width: 110px;
  top: 20px;

  ${media.landscape`
    top: 5px;
    left: 5px;
  `}

  ${media.mobile`
    top: unset;
    bottom: 5px;
    left: 5px;
    width: 110px;
  `}
`;

const LeftLayout = (props) => {
  return (
    <Background background={props.background}>
      <SideBar>
        <Title>Tusitawi!</Title>
        <MainSection>
          <TopSection>
            <QuickLoginWrapper>
              {props.quickLogins.map(({ Avatar, FirstName, UserID }, i) => (
                <QuickLogin
                  key={UserID}
                  style={{ color: "white" }}
                  avatar={LoginStyleService.getAvatarPath(Avatar)}
                  onClick={props.onQuickLogin(UserID)}
                  layout="left"
                >
                  {FirstName}
                </QuickLogin>
              ))}
            </QuickLoginWrapper>
          </TopSection>
          <BottomSection>
            <form onSubmit={props.onSubmit}>
              <Field>
                <Label for="username">Username</Label>
                <input
                  autoFocus
                  name="username"
                  id="username"
                  type="text"
                  onChange={props.onChange}
                  value={props.username}
                />
              </Field>

              <Field>
                <Label for="password">Password</Label>
                <input
                  name="password"
                  id="password"
                  type="password"
                  onChange={props.onChange}
                  value={props.password}
                />
              </Field>

              {props.error && (
                <span
                  style={{ marginTop: "6px", fontSize: "13pt", color: "red" }}
                  center
                >
                  {props.error}
                </span>
              )}

              <Field style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "128px", color: "#28483a", fontSize: "25px" }}
                  type="submit"
                >
                  <i className="fas fa-arrow-right" />
                </Button>
              </Field>
            </form>
          </BottomSection>
        </MainSection>
      </SideBar>
      <ClientLogo src={props.clientLogo} />
      <SystemLogo />
    </Background>
  );
};

export default LeftLayout;