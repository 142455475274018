import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import InputWrapper from "./InputWrapper";

const StyledTextArea = styled(TextareaAutosize)`
  outline: none;
  border: none;
  flex-grow: 1;
  width: 100%;
  resize: none;
  size: unset;
  font-size: 20px;
  font-family: Roboto;
  background-color: transparent;
  padding: 8px 0;
`;
StyledTextArea.displayName = "StyledTextArea";

const InputArea = forwardRef((props, ref) => {
  const [state, setState] = useState({ focused: false });

  const handleFocus = () => {
    setState({ focused: true });
  };
  const handleBlur = () => {
    setState({ focused: false });
  };

  const { style, label, ...rest } = props;

  return (
    <InputWrapper
      ref={ref}
      label={label}
      active={!!rest.value}
      textArea
      focused={state.focused}
      style={style}
    >
      <StyledTextArea onFocus={handleFocus} onBlur={handleBlur} {...rest} />
    </InputWrapper>
  );
});

export default InputArea;
