import { LoginService } from "services";

const initialState = {
  user: null,
};

export const HYDRATE_CURRENT_USER = "@auth/HYDRATE_CURRENT_USER";
export const hydrateCurrentUser = (user) => ({
  type: HYDRATE_CURRENT_USER,
  payload: user,
});

export const getCurrentUser = () => async (dispatch) => {
  if (sessionStorage.getItem("token")) {
    dispatch(hydrateCurrentUser(null));
    const user = await LoginService.getCurrentUser();
    if (user && user.Roles[0]) {
      user.PrimaryRole = user.Roles[0].Name;
    }
    dispatch(hydrateCurrentUser(user || false));
  } else {
    dispatch(hydrateCurrentUser(false));
  }
};

const auth = function (state = initialState, action) {
  switch (action.type) {
    case HYDRATE_CURRENT_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default auth;
