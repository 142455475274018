const LOGIN_BASE_PATH = process.env.PUBLIC_URL + "/custom/login/";
const BACKGROUNDS_BASE_PATH = LOGIN_BASE_PATH + "backgrounds/";
const LOGO_BASE_PATH = LOGIN_BASE_PATH + "logos/";
const AVATARS_BASE_PATH = LOGIN_BASE_PATH + "avatars/";

const LOGIN_JSON_PATH = LOGIN_BASE_PATH + "login.json";

let cached_json = null;

export default class LoginStyleService {
  static async getLoginJson() {
    if (!cached_json) {
      const response = await fetch(LOGIN_JSON_PATH);
      const json = await response.json();
      cached_json = json;
    }
    return cached_json;
  }

  static async getLayout() {
    const json = await this.getLoginJson();
    return json.layout;
  }

  static async getBackgroundPath() {
    const json = await this.getLoginJson();
    const background = json.background;
    return BACKGROUNDS_BASE_PATH + background;
  }

  static async getLogoPath() {
    const json = await this.getLoginJson();
    const logo = json.logo;
    return LOGO_BASE_PATH + logo; 
  }

  static async getAvatars() {
    const json = await this.getLoginJson();
    return json.avatars;
  }

  static getAvatarPath(avatar) {
    if (!avatar) {
      return null;
    }

    let avatarFile = avatar;
    if (avatar.indexOf(".") === -1) {
      avatarFile += ".svg";
    }

    return AVATARS_BASE_PATH + avatarFile;
  }
}
