import styled from 'styled-components';

export default styled.div`
  background-color: white;
  border-radius: 5px;
  border: 2px solid #DEDEDE;
  padding: 32px;
  margin-top: 128px;
  margin-bottom: 32px;
  box-sizing: border-box;
  box-shadow: 1px 1px 8px 0 rgba(0,0,0,0.16);
`;