import _update from 'immutability-helper';

let initialState = sessionStorage.getItem('tools');
if (initialState) {
  initialState = JSON.parse(initialState);
} else {
  initialState = {
    inFront: 'calculator',
    calculator: {
      visible: false,
      dragging: false,
      top: 85,
      left: 415
    },
    dictionary: {
      visible: false,
      dragging: false,
      top: 85,
      left: 15
    }
  };
}

export const SET_VISIBILITY = 'SET_VISIBILITY';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_DRAGGING = 'SET_DRAGGING';
export const SET_INFRONT = 'SET_INFRONT';

const update = (obj, update_operation) => {
  let nextState = _update(obj, update_operation);
  sessionStorage.setItem('tools', JSON.stringify(nextState));
  return nextState;
}

const tools = (state = initialState, action) => {
  const slash = action.type.indexOf("/");
  const actionType = action.type.substring(slash + 1);
  const actionTarget = action.type.substring(1, slash);
  switch (actionType) {
    case SET_VISIBILITY:
      return update(state, {
        [actionTarget]: { visible: { $set: action.payload } },
      });
    case SET_LOCATION:
      return _update(state, {
        [actionTarget]: {
          top: { $set: action.payload.top },
          left: { $set: action.payload.left },
          dragging: { $set: false },
        },
      });
    case SET_DRAGGING:
      return _update(state, {
        [actionTarget]: { dragging: { $set: action.payload } },
      });
    case SET_INFRONT:
      return _update(state, { inFront: { $set: action.payload } });
    default:
      return state;
  }
};
export default tools;

export const setVisibility = (tool, visible) => ({ type: `@${tool}/${SET_VISIBILITY}`, payload: visible });
export const setLocation = (tool, { top, left }) => ({ type: `@${tool}/${SET_LOCATION}`, payload: { top, left } });
export const setDragging = (tool, payload) => ({ type: `@${tool}/${SET_DRAGGING}`, payload });
export const setInFront = (tool) => ({ type: `@${tool}/${SET_INFRONT}`, payload: tool });