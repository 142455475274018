import React from 'react';
import styled from 'styled-components';
import color from 'color';
import {
  bool,
  func
} from 'prop-types';

const CLR_PRIMARY = '#076693';
const CLR_SECONDARY = 'black';
const CLR_TERTIARY = 'white';
const CLR_QUARTERNARY = '#0A74A0';

const darken = more => ({ secondary, tertiary, quarternary, disabled }) => {
  if (disabled) {
    return '#e3e3e3';
  }

  if (secondary) {
    return color(CLR_SECONDARY).rgb().lightness(more ? 30 : 25).string();
  }

  if (tertiary) {
    return color(CLR_TERTIARY).rgb().darken(more ? 0.3 : 0.15).string();
  }

  if (quarternary) {
    return CLR_QUARTERNARY;
  }

  return color(CLR_PRIMARY).rgb().darken(more ? 0.3 : 0.15).string();
};

const evaluateBackgroundColor = border => ({ secondary, tertiary, quarternary, disabled }) => {
  if (disabled) {
    return '#E3E3E3';
  }

  if (tertiary) {
    if (border) {
      return '#95989A';
    }
    return CLR_TERTIARY;
  }

  if (quarternary) {
    if (border) {
      return CLR_QUARTERNARY;
    }
    return 'transparent';
  }

  return secondary ? CLR_SECONDARY : CLR_PRIMARY;
}

const evaluateColor = active => ({ tertiary, quarternary }) => {
  if (tertiary) return '#95989A';
  if (quarternary) {
    if (active) return 'white';
    return CLR_QUARTERNARY;
  }
  return 'white';
};

const Button = styled.button`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  height: 30px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: ${evaluateBackgroundColor()};
  border: 1px solid ${evaluateBackgroundColor(true)};
  font-family: Roboto;
  color: ${evaluateColor()};
  font-size: 16px;
  border-radius: 15px;
  transition: background-color 0.05s linear;
  z-index: inherit;

  &:hover, &:focus {
    background-color: ${darken()};
    color: ${evaluateColor(true)};
  }

  &:active {
    background-color: ${darken(true)};
    color: ${evaluateColor(true)};
  }
`;
Button.displayName = 'Button';

const Icon = styled.img`
  height: 100%;
  width: 36px;
  pointer-events: none;
`;
/**
 * Generic Button used extensively throughout the L4H-Frontend project.
 * 
 * Commonly used with the _*Field*_ component.
 */
const ShortButton = (props) => {
  const { icon } = props;

  return (
    <Button {...props}>
      {icon && <Icon src={icon} alt="add" />}
      {props.children}
    </Button>
  );
};

ShortButton.propTypes = {
  /** Disables click-events from the button, and greys it out. */
  disabled: bool,
  /** Styles the button as a secondary action. */
  secondary: bool,
  /** Styles the button as a 3rd less important / generic action. */
  tertiary: bool,
  quarternary: bool,
  options: bool,
  onClick: func,
};
ShortButton.defaultProps = {
  disabled: false,
  secondary: false,
  tertiary: false,
  quarternary: false,
};

export default ShortButton;