import React from 'react';
import styled from 'styled-components';

const NInput = styled.input`
  outline: none;
  height: 48px;
  border: 2px solid #c8c8c8;
  background-color: transparent;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Roboto;
  color: #c8c8c8;
  font-size: 22px;
  width: 100%;
`;
NInput.displayName = 'NInput';

const ChalkboardInput = (props) => {
  return <NInput {...props} />;
};

export default ChalkboardInput;