import React, { createContext, useCallback, useState } from "react";
import styled from "styled-components";

const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
TooltipWrapper.displayName = "TooltipWrapper";

const TooltipOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  pointer-events: none;
`;
TooltipOverlay.displayName = "TooltipOverlay";

export const TooltipContext = createContext({});

const TooltipProvider = (props) => {
  const [state, setState] = useState({
    currentTooltipId: "",
    tooltipComponent: null,
    showTooltip: false,
  });

  const setShowTooltip = useCallback(
    (currentTooltipId, tooltipComponent, showTooltip) => {
      if (!currentTooltipId) return;

      setState((orig) => {
        if (currentTooltipId === orig.currentTooltipId) {
          return {
            ...orig,
            tooltipComponent,
            showTooltip,
          };
        } else if (showTooltip) {
          return {
            currentTooltipId,
            tooltipComponent,
            showTooltip,
          };
        }

        return orig;
      });
    },
    []
  );

  const clearTooltip = useCallback((tooltipId) => {
    setState((orig) => {
      if (tooltipId === orig.currentTooltipId) {
        return {
          currentTooltipId: "",
          tooltipComponent: null,
          showTooltip: false,
        };
      }

      return orig;
    });
  }, []);

  return (
    <TooltipContext.Provider value={{ setShowTooltip, clearTooltip }}>
      <TooltipWrapper>
        {props.children}
        <TooltipOverlay>
          {state.showTooltip && state.tooltipComponent}
        </TooltipOverlay>
      </TooltipWrapper>
    </TooltipContext.Provider>
  );
};

export default TooltipProvider;
