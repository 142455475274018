import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";

import InputWrapper from "./InputWrapper";

const StyledInput = styled.input`
  outline: none;
  border: none;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  size: unset;
  font-size: ${({ big }) => (big ? "40px" : "20px")};
  font-family: Roboto;
  background-color: transparent;

  border-radius: 6px;

  &:not(:focus) {
    color: #95989a;
  }
`;
StyledInput.displayName = "StyledInput";

const Icon = styled.img`
  width: 30px;
  margin-left: 8px;
`;
Icon.defaultProps = { alt: "Search" };
Icon.displayName = "Icon";

const LIVE_EDIT_DEBOUNCE = 1000;

const initState = {
  focused: false,
  editing: false,
};

const Input = forwardRef((props, ref) => {
  const input = useRef();

  const {
    onFocus,
    onBlur,
    onChange,
    onComplete,
    icon,
    style,
    inputStyle,
    label,
    big,
    error,
    value,
    ...rest
  } = props;

  useImperativeHandle(ref, () => input.current);

  const prevState = useRef(initState);
  const timeoutRef = useRef();

  const [state, _setState] = useState(initState);

  const setState = useCallback((newState) => {
    _setState((orig) => {
      prevState.current = orig;
      return newState;
    });
  }, []);

  const handleClick = useCallback(() => {
    input.current.focus();
  }, []);

  const handleFocus = useCallback(
    (e) => {
      setState((orig) => ({ ...orig, focused: true }));
      onFocus && onFocus(e);
    },
    [onFocus, setState]
  );

  const handleBlur = useCallback(
    (e) => {
      setState((orig) => ({
        ...orig,
        focused: false,
        editing: orig.editing && false,
      }));
      // if (state.editing) {
      //   setState((orig) => ({ ...orig, editing: false }));
      //     , () => {
      //     props.onComplete();
      //   });
      // }
      onBlur && onBlur(e);
    },
    [onBlur, setState]
  );

  const handleComplete = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
    setState((orig) => ({ ...orig, editing: false }));
  }, [setState]);

  const handleChange = useCallback(
    (e) => {
      if (onComplete) {
        if (!state.editing) {
          setState((orig) => ({ ...orig, editing: true }));
        }
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          handleComplete();
        }, LIVE_EDIT_DEBOUNCE);
      }

      onChange && onChange(e);
    },
    [onComplete, onChange, state.editing, handleComplete, setState]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (state.editing) {
        switch (e.which) {
          case 13: // Enter
            handleComplete();
            break;
          default:
            break;
        }
      }
    },
    [state.editing, handleComplete]
  );

  useEffect(() => {
    if (prevState.current.editing && !state.editing) {
      onComplete && onComplete();
    }
  }, [state.editing, onComplete]);

  const inputValue = useMemo(() => value || "", [value]);

  return (
    <InputWrapper
      active={!!inputValue}
      onClick={handleClick}
      focused={state.focused}
      big={big}
      style={style}
      label={label}
      error={error}
    >
      {props.icon && <Icon src={props.icon} />}
      <StyledInput
        ref={input}
        value={inputValue}
        style={inputStyle}
        big={big}
        {...rest}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={state.focused || !label ? rest.placeholder : undefined}
      />
    </InputWrapper>
  );
});

export default Input;
