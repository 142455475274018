import update from 'immutability-helper';

import SchoolService from 'services/administration/SchoolService';
import merge_records from 'utils/merge_records';

const initialState = {
  schools: []
};

export const UPDATE_SCHOOLS = '@school/UPDATE_SCHOOLS';
export const UPDATE_SCHOOL = '@school/UPDATE_SCHOOL';

const school = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCHOOLS: {
      return update(state, {
        schools: merge_records(action.payload, "SchoolID"),
      });
    }

    case UPDATE_SCHOOL: {
      return update(state, {
        schools: merge_records([action.payload], "SchoolID"),
      });
    }

    default:
      return state;
  }
};

export default school;

/**
 * Synchronous Action Creators
 */

export const updateSchools = schools => ({
  type: UPDATE_SCHOOLS,
  payload: schools
});

export const updateSchool = school => ({
  type: UPDATE_SCHOOL,
  payload: school
});

/**
 * Asynchronous Action Creators
 */

export const getSchools = (props) => async dispatch => {
  try {
    dispatch(updateSchools(
      await SchoolService.fetch(props)
    ));
  } catch (e) {
    console.error(e);
  }
};

export const saveSchool = (school, role) => async (dispatch) => {
  try {
    const s = await SchoolService.save({ school, role });
    dispatch(updateSchool(s));
    return s;
  } catch (e) {
    console.error(e);
  }
};